import {
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
} from '@remix-run/node';

import { getLogger } from '../../src/logger/logger';

export const log = getLogger().scoped('zoom');

export async function loader({ request }: LoaderFunctionArgs) {
  log.info('zoom log loader', {
    url: request.url,
  });
  return 'ok';
}

export async function action({ request }: ActionFunctionArgs) {
  const body = await request.text();
  log.info('zoom log action', {
    url: request.url,
    body,
  });
  return 'ok';
}
